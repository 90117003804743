<template>
  <header class="navbar is-unselectable">
    <a
      class="navbar-item is-hidden-touch toggle-menu"
      @click="toggleSidebar"
    >
      <i class="material-icons">menu</i>
    </a>
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        to="/"
      >
        <img
          class="is-hidden-mobile"
          src="@/assets/givu.png"
          alt="Givu"
        >
        <img
          src="@/assets/givu.png"
          class="is-hidden-tablet"
          alt="givu"
        >
      </router-link>
      <div
        class="navbar-burger burger"
        data-target="navMenu"
        @click="toggleSidebar"
      >
        <span/>
        <span/>
        <span/>
      </div>
    </div>
    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': sidebar }"
    >
      <div class="navbar-end">
        <div class="navbar-item has-dropdown notication-container is-hoverable">
          <a class="navbar-link is-arrowless" @click="toggleMenu">
           <span class="material-icons" :class="[notificationPending > 0 ? 'not' : '']">
              notifications
            </span>
          </a>
          <div v-if="menu" class="navbar-dropdown notification-list">
            <template v-for="notification in notifications">
              <a
                :key="notification.id"
                class="navbar-item notifications"
                @click="changeStatus(notification.id, notification.productId, notification.userid)"
              >
                <div class="is-flex content-notifications">
                  <div class="content-img">
                    <img class="userImage" :src="notification.imageurl"  @error="onImageError">
                  </div>
                  <div class="content-text" :class="[notification.opened ? 'opened' : '']">
                    {{notification.notification}}
                    <br/>
                    <span>{{formatDateTimestamp(notification.createdAt)}}</span>
                  </div>
                </div>
              </a>
            </template>
            <div class="has-text-centered">
              <a class="button show-notification" :class="[isLoading ? 'is-loading': '']" @click="seeMore()">Ver más</a>
            </div>
          </div>
        </div>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            {{ authentication.name }}
          </a>

          <div class="navbar-dropdown">
            <a
              class="navbar-item"
              @click="signOut"
            >
              <i class="material-icons">exit_to_app</i>
              Cerrar Sesión
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import mixin from '../mixin/validateDate'
export default {
  name: 'TheHeader',
  mixins: [mixin],
  computed: {
    ...mapState({
      authentication: state => state.authentication,
      sidebar: state => state.ui.sidebar
    })
  },
  data () {
    return {
      notifications: [],
      notificationPending: 0,
      page: 1,
      isLoading: false,
      menu: false,
      fallbackImageUrl: 'https://givuapp.s3.amazonaws.com/logo-givu.jpeg'
    }
  },
  methods: {
    ...mapMutations(['toggleSidebar', 'signOut']),
    ...mapActions(['getAdminNotifications', 'openNotification']),
    toggleMenu () {
      this.menu = !this.menu
    },
    getNotifications () {
      this.isLoading = true
      this.getAdminNotifications({ page: this.page })
        .then(resp => {
          this.notifications = [...this.notifications, ...resp.payload]
          this.notificationPending = resp.payload.filter(not => not.opened === false)
          this.notificationPending = this.notificationPending.length
          this.isLoading = false
        })
    },
    changeStatus (notificationId, productId, userId) {
      this.openNotification(notificationId)
        .then(resp => {
          this.getNotifications()
        })

      let urlToOpen
      if (productId && this.$router.history.current.params.productId !== productId) {
        urlToOpen = `${window.location.origin}/productos/detalle/${productId}`
      } else if (!productId && this.$router.history.current.params.userId !== userId) {
        urlToOpen = `${window.location.origin}/usuarios/detalle/${userId}`
      }

      if (urlToOpen) {
        window.open(urlToOpen, '_blank').focus()
      }
    },
    seeMore () {
      this.page++
      this.getNotifications()
    },
    onImageError (event) {
      event.target.src = this.fallbackImageUrl
    }
  },
  mounted () {
    this.getNotifications()
  }
}
</script>

<style scoped lang="scss">
  header {
    border: solid 1px #e6e6e6;
  }
  .navbar-brand {
    background-color: #fff;
  }
  .notication-container {
    display: flex;
    justify-content: space-around;
  }

  .toggle-menu {
    cursor: pointer;
  }

  .navbar-menu .navbar-dropdown .navbar-item {
    display: flex;
    align-items: center;
  }

  .navbar-menu .navbar-dropdown .navbar-item i {
    padding-right: 4px;
  }

  @media screen and (max-width: 768px) {
    .hero header.navbar {
      background: var(--secondary-color);
      border: solid 1px rgba(0, 0, 0, .15);
    }

    .navbar-burger {
      color: #4a4a4a;
    }

    .navbar-menu.is-active {
      box-shadow: none;
      background: var(--secondary-color);
    }

    .navbar-menu.is-active a {
      color: white;
    }

    .navbar-menu.is-active a:hover {
      background: var(--primary-color-light)
    }
  }

  @media screen and (min-width: 769px) {
    .hero header.navbar {
      background: white;
    }
  }
  .notifications {
    white-space: break-spaces;
  }
  .material-icons.not::after {
    content:'';
    width: 10px;
    height: 10px;
    background: #cd3d27;
    position: absolute;
    border-radius: 10px;
    top: 12px;
    left: 25px;
    border: 2px solid #fff;
  }
  .notification-list {
    margin-left: -150px;
    width: 250px;
    height: 400px;
    overflow-y: scroll;
  }
  .content-notifications {
    .content-img {
      margin-right: 10px;
      .userImage {
        width: 40px;
        height: 40px;
        max-height: 40px;
        max-width: 40px;
        background-position: 0;
        border-radius: 50%;
      }
    }
    .content-text {
      font-size: 11px;
      font-weight: 500;
      &.opened {
        color: #909090;
        font-weight: 400;
      }
      span {
        font-size: 8px;
        color: #2E89FF;
        widows: 100%;
      }
    }
  }
  .navbar-dropdown a.navbar-item.notifications {
      padding-right: 0.75rem;
  }
  .navbar-dropdown.notification-list::after {
    content: '';
    border-color: #fff
  }
  .show-notification {
    border: 0;
    font-size: 12px;
    color: #344675;
    font-weight: 600;
  }
  .navbar-item.has-dropdown.is-hoverable:hover .navbar-dropdown {
    left: auto;
    right: 0;
  }
</style>
