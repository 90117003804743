export default [
  {
    title: 'Administrador',
    index: 1,
    options: [
      {
        id: 1,
        label: 'Dashboard',
        to: '/dashboard',
        icon: 'dashboard'
      },
      {
        id: 2,
        label: 'Productos de Usuarios',
        to: '/productos',
        icon: 'local_offer'
      },
      {
        id: 17,
        label: 'Productos de GivU',
        to: '/inventario',
        icon: 'inventory_2'
      },
      {
        id: 3,
        label: 'Usuarios',
        to: '/usuarios',
        icon: 'group'
      },
      {
        id: 4,
        label: 'Leads',
        to: '/leads',
        icon: 'groupadd'
      },
      {
        id: 5,
        label: 'Categorías',
        to: '/categorias',
        icon: 'category'
      },
      {
        id: 6,
        label: 'Caracteristicas',
        to: '/caracteristicas',
        icon: 'more_horiz'
      },
      {
        id: 7,
        label: 'Reclamos',
        to: '/reclamos',
        icon: 'message'
      },
      {
        id: 8,
        label: 'Ventas',
        to: '/ventas',
        icon: 'shopping_cart'
      },
      {
        id: 9,
        label: 'Transacciones',
        to: '/transacciones',
        icon: 'monetization_on'
      },
      // {
      //   id: 9,
      //   label: 'Banco',
      //   to: '/banco',
      //   icon: 'account_balance'
      // },
      {
        id: 10,
        label: 'Comentarios',
        to: '/comentarios',
        icon: 'comment'
      },
      {
        id: 16,
        label: 'Notificaciones',
        to: '/notificaciones',
        icon: 'notifications'
      },
      {
        id: 11,
        label: 'Administradores',
        to: '/administradores',
        icon: 'person'
      },
      // {
      //   id: 12,
      //   label: 'Promociones',
      //   to: '/promociones',
      //   icon: 'grade'
      // },
      {
        id: 13,
        label: 'Reportes',
        to: '/reportes',
        icon: 'grade'
      }
      // {
      //   id: 14,
      //   label: 'Blog',
      //   to: '/blog',
      //   icon: 'feed'
      // },
      // {
      //   id: 15,
      //   label: 'Prensa',
      //   to: '/prensa',
      //   icon: 'wysiwyg'
      // }
    ]
  }
]
